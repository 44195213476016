import { useEffect } from 'preact/hooks';
import { useExperiment } from '@nerdwallet/features';
import { spacing } from '@nerdwallet/base-styles/variables';
import ApolloWrapper from 'nav2/auth/global/ApolloWrapper';

const TEST_NAME = 'global-nav-sticky-test-q1-2025';

const HeaderExperimentInner = () => {
  const { assignedVariantName, loading } = useExperiment({
    testName: TEST_NAME,
  });

  /**
   * If the experiment is still loading, or if the assigned variant is
   * an empty string (meaning the user is not part of the experiment audience)
   * or the fallback value 'control' (in lowercase, which is returned
   * when the experiment is not supported), we return null to avoid
   * applying any changes on unsupported pages
   *
   * fallback value is defined in:
   * https://github.com/NerdWallet/nwjs-features/blob/master/src/experiment/experimentImpl.tsx#L44
   *
   */
  if (loading || !assignedVariantName || assignedVariantName === 'control') {
    return null;
  }

  useEffect(() => {
    const root = document.documentElement;

    const isHeaderSticky = assignedVariantName === 'Treatment';

    root.style.setProperty(
      '--sidebar-sticky-top',
      isHeaderSticky
        ? `calc(${spacing[2]} + var(--global-nav-header-height))`
        : spacing[2]
    );

    const header = document.querySelector('header');

    if (header) {
      header.setAttribute('data-isHeaderSticky', String(isHeaderSticky));
    }
  }, [assignedVariantName]);

  return null;
};

const HeaderExperiment = () => {
  return (
    <ApolloWrapper>
      <HeaderExperimentInner />
    </ApolloWrapper>
  );
};
export default HeaderExperiment;
